/* Main container */
.bill-container {
    display: flex;
    flex-wrap: wrap; /* Wrap items to the next row when space runs out */
    justify-content: center; /* Adds space between items */
    gap: 16px; /* Space between rows and columns */
    padding: 16px;
    border: none;
  }


  /* Each item */
  .bal-item {
    flex: 1 1 calc(25% - 16px); /* Flexible width: 4 items per row */
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    border-color: black;
    border-radius: 10px;
    border-width: 2px;
    transition: transform 0.2s ease-in-out;
    border-left: 4px solid black;
    max-height: 90px;
    height: 90px;
    overflow: hidden;
    max-width: 90px;
    width: 90px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth transitions */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
  }

  .autocomplete-item {
    flex: 2 2 calc(55% - 16px); /* Flexible width: 4 items per row */
    transition: transform 0.2s ease-in-out;
    max-height: 56px;
    height: 56px;
    max-width: 700px;
    background-color: white;
    width: 700px;
  }
  
  /* Each item */
  .item {
    flex: 2 2 calc(25% - 16px); /* Flexible width: 4 items per row */
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
    border-color: black;
    border-radius: 10px;
    border-width: 2px;
    transition: transform 0.2s ease-in-out;
    max-height: 280px;
    height: 280px;
    overflow: hidden;
    max-width: 200px;
    border-left: 4px solid black;
    width: 200px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; /* Smooth transitions */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
  }
  
  .item:hover {
    transform: translateY(-12px); /* Slight lift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }

  .bal-item:hover {
    transform: translateY(-12px); /* Slight lift on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  }

  .item-img {
    width: 75px; /* Set image size */
    height: 75px;
    object-fit: contain; /* Ensures the image doesn't distort */
    margin-bottom: 9px;
  }
  
 
  
  /* Media query for tablets */
  @media (max-width: 768px) {
    .item {
      flex: 1 1 calc(50% - 16px); /* 2 items per row on smaller screens */
    }
  }
  
  /* Media query for phones */
  @media (max-width: 480px) {
    .item {
      flex: 1 1 100%; /* Full width for each item */
    }
  }
  