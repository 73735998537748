.rate_card thead tr{
    background: #01333E;
}

.rate_card thead th{
    color: white;
}

.rate_card--row:nth-of-type(even) {
    background: whitesmoke;
}

div .rate_card--button {
    margin-right: 10px;
    background: #01333E;
    color: white;
}

div .rate_card__button--container {
    margin-bottom: 10px;
}

svg[data-testid="EditIcon"] {
    cursor: pointer;
}