@charset "UTF-8";

@import
    "ekartHandset",
    "ekartTablet",
    "ekartDesktop",
    "ekartExtraLarge";


.MuiAutocomplete-root .MuiOutlinedInput-root {
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.MuiAutocomplete-root .MuiOutlinedInput-root input.MuiOutlinedInput-input,
.MuiOutlinedInput-root input.MuiOutlinedInput-input[placeholder="dd/mm/yyyy"]{
    box-shadow: none;
}

