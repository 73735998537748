.table-cell-trucate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  .out-of-stock-row {
    background-color: #dc6a77; /* Light red */
  }
  
  .no-color-row {
    background-color: rgba(0, 255, 255, 0); /* Light green */
  }
  