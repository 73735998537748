@import url("https://fonts.googleapis.com/css?family=Open+Sans");


.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.three-d-text-container {
  margin-bottom: 0px; /* Default margin for larger screens */
}

@media (max-width: 480px) {
  .three-d-text-container {
    margin-bottom: 30px; /* Further reduced margin for very small screens */
  }
}

