.bottom-sheet {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  background: #c6d1d3;
  height: 50vh;
  z-index: 9999;
  margin-left: 98px;
  margin-right: 20px;
  margin-bottom: 2px;
  padding-top: 3px;
  padding-bottom: 10px;
  border-radius: 10px;
  border-color: aqua;
  border-width: 2ch;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.bottom-sheet.open {
  bottom: 0;
}

.content {
  padding: 20px;
  max-height: 100%;
  overflow-y: auto;
}