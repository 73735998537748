*{
    box-sizing: border-box;
}
div{
    font-size: 17px;
    
    
}
.container{
    
    padding: 5px 20px 15px 20px;
    border: 1px solid lightgray;
    border-radius: 4px;
}

.feature-data-container{
    border-left: 5px solid #01333e;
    border-radius: 5px;
    background: white;
    height: 100px;
    width: 80px;
    padding: 1px;
    justify-content: center;
    text-align: center;
}
