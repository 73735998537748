.chart-container{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    /* grid-template-rows: repeat(3, 1fr); */
    column-gap: 15px;
    row-gap: 15px;
    padding: 0 15px;
    justify-items: center;
    margin-top: -5px;
}
.item-search{
    background-color: white;
}
.chart{
    background-color: white;
    width: 560px;
}

.chart-pie {
    grid-area: 1 / 2 / span 2 / span 2;
}
