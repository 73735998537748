.threeDcontainer {
    display: flex;
    justify-content: left;
    align-items: left;
    height: 50.5px;
  }
  
  .threeDText {
    font-size: 1.5rem;
    font-weight: bold;
    color: #2f1160;
    font-family: monospace;
    text-shadow: 
      1px 1px 0px #ff00001f, 
      2px 2px 0px #00ff0018, 
      3px 3px 0px #2f116011, 
      4px 4px 5px rgba(0, 0, 0, 0.2), 
      5px 5px 10px rgba(0, 0, 0, 0.3);
    perspective: 100px;
  }

 
  