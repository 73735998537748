:root {
  --common-card-width-desktop: 290px;
  --common-card-width-tablet: 250px;
  --common-card-width-mobile: 180px;
}



.card-name {
  display: flex;
  flex-direction: column;
}


.category-name {
  color: grey;
  font-size: 0.9rem;
}


.product-container {
  background: white;
  height: 370px;
  width: var(--common-card-width-desktop);
  padding: 1px;
  display: flex;
  justify-content: center;
  align-content: center;
  max-height: 370px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .product-container {
    background: white;
    height: 350px;
    width: var(--common-card-width-mobile);
    padding: 1px;
    display: flex;
    justify-content: center;
    align-content: center;
    max-height: 350px;
    overflow-y: auto;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .product-container {
    background: white;
    height: 350px;
    width: var(--common-card-width-tablet);
    padding: 1px;
    display: flex;
    justify-content: center;
    align-content: center;
    max-height: 350px;
    overflow-y: auto;
  }
}

.div-img-container {
  height: 190px;
  width: var(--common-card-width-desktop);
  justify-content: center;
  align-content: center;
}

@media (max-width: 767px) {
  .div-img-container {
    height: 190px;
    width: var(--common-card-width-mobile);
    justify-content: center;
    align-content: center;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  .div-img-container {
    height: 190px;
    width: var(--common-card-width-tablet);
    justify-content: center;
    align-content: center;
  }
}


.product-container:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}


.scroll-container {
  display: flex;
  width: 680px;
  position: relative;
  /* top: 40px; */
  left: 50%;
  transform: translateX(-50%);
  overflow-x: scroll;
  gap: 20px;
  padding: 10px;
}

@media (max-width: 767px) {
  .scroll-container {
    display: flex;
    width: 375px;
    position: relative;
    /* top: 40px; */
    left: 50%;
    transform: translateX(-50%);
    overflow-x: scroll;
    gap: 10px;
    padding: 10px;
  }
}

.scroll-container-vertical {
  display: flex;
  flex-direction: column;
  /* Ensure content flows vertically */
  width: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 300px;
  /* Set a fixed height to trigger scrolling */
  overflow-y: scroll;
  /* Enable vertical scrolling */
  gap: 20px;
  padding: 10px;
}

/* Optional: Custom scrollbar styling (Webkit-based browsers) */
.scroll-container-vertical::-webkit-scrollbar {
  width: 8px;
}

.scroll-container-vertical::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scroll-container-vertical::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}



.cart-img-container {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
}

.mybills-container {
  background: white;
  width: 100%; /* Make it responsive */
  max-width: 100%; /* Ensure it doesn't stretch beyond the parent */
  max-width: max-content;
  display: flex;
  flex-direction: column; /* Stack the items if needed on small screens */
}


.cateogory-container {
  background: white;
  height: 120px;
  width: 150px;
  max-width: 150px;
  min-width: 150px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-content: center;
}

@media (max-width: 767px) {
  .cateogory-container {
    background: white;
    height: 120px;
    width: 120px;
    max-width: 120px;
    min-width: 120px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}




/*Carousel Css */

.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: var(--common-card-width-desktop);
  /* Adjust the width */
  margin: auto;
}

@media (max-width: 767px) {
  .carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: var(--common-card-width-mobile);
    /* Adjust the width */
    margin: auto;
  }
}

.carousel-image-container {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  /* Ensures the container is a perfect circle */
  overflow: hidden;
  /* Ensures the image stays within the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  /* Optional border */
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensures the image covers the container and stays within the circle */
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background-color: #717171;
}