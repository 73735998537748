@media screen and (min-width: 210px) {
    
}

//      *************************************** GLOBAL
.error {
    color: red;
}

.success {
    color: forestgreen;
}


@mixin drCr_color($drCr) {
    @debug "debug drCr:#{$drCr}";
    @if $drCr == 'Cr' {
        color: forestgreen
    } @else {
        color: red;
    }
    
  }


#accounting div[data-field="messagegfg"],
#accounting div[data-field="entryByhj"],
#accounting div[data-field="createdAthjhg"] {
    color: black;
}


#accounting div[title="Drss"] {
    color: red;
}

#accounting div[title="Crsss"] {
    color: forestgreen;
}