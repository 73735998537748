.slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  max-width: 700px;
  /* Max width for container */
  margin: 0 auto;
  /* Center the container */
  border-radius: 5%;
}

@media (max-width: 767px) {
  .slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    max-width: 200px;
    /* Max width for container */
    margin: 0 auto;
    /* Center the container */
    border-radius: 5%;
  }
}

.slider {
  display: flex;

  animation: slide 30s infinite;
}

.slide {
  min-width: 100%;
  position: relative;
  transition: transform 0.5s ease;
}

.image {
  width: 700px;
  height: 400px;
  /* Fixed height */
  object-fit: cover;
  /* Ensure image aspect ratio is maintained */
  filter: blur(2px);
  /* Light blur effect */
  transition: filter 0.5s ease;
  border-radius: 0;
}

@media (max-width: 767px) {
  .image {
    width: 200px;
    height: 200px;
    /* Fixed height */
    object-fit: cover;
    /* Ensure image aspect ratio is maintained */
    filter: blur(2px);
    /* Light blur effect */
    transition: filter 0.5s ease;
    border-radius: 0;
  }
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(22, 20, 20);
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(180, 173, 173, 0.5);
  text-align: center;
  /* Ensure multiline text is centered */
  width: 100%;
  /* Adjust width to fit the text nicely */
  /* Improve readability for multiline text */
  z-index: 1;
}

@keyframes slide {
  0% {
    transform: translateX(0);
    /* Start at the first slide */
  }

  80% {
    /* Move to the last slide */
    transform: translateX(calc(-100% * (var(--numSlides) - 1)));
    /* Move to the last slide */
  }

  82% {
    /* Pause before going back to the first slide */
    transform: translateX(calc(-100% * (var(--numSlides) - 1)));
    /* Hold on the last slide */
  }

  100% {
    /* Move back to the first slide */
    transform: translateX(0);
    /* Go back to the first slide */
  }
}