.flip-container {
  perspective: 1000px;
}

.flip-card {
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card.flipped-left {
  transform: rotateY(-180deg);
}

.flip-card.flipped-right {
  transform: rotateY(180deg);
}

.flip-card .flip-front, 
.flip-card .flip-back {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
}

.flip-card .flip-back {
  transform: rotateY(180deg);
}
  

  /* Initially hidden */
.hidden {
  display: none;
}

/* Slide in from left */
.slide-in-left {
  opacity: 0;
  transform: translateX(-100%);
  animation: slideLeft 0.5s forwards;
}

@keyframes slideLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Slide in from right */
.slide-in-right {
  opacity: 0;
  transform: translateX(100%);
  animation: slideRight 0.5s forwards;
}

@keyframes slideRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
